'use client';

import React from 'react';

import { Error as ErrorUI } from '@/components/Error';
import {
  getLogger,
  getUsefulErrorContents,
} from '@/modules/observability/logging.ts';
import { Stdv1Stat } from '@/modules/observability/constants';
import { recordMetric } from '@/modules/observability/metrics';

type Props = {
  error: Error & { digest?: string };
  reset: () => void;
};

export default function ErrorBoundary({ error }: Props) {
  getLogger().error(
    'App Locale ErrorBoundary caught an error',
    getUsefulErrorContents(error, 'App Locale ErrorBoundary'),
    error
  );

  recordMetric({
    type: 'increment',
    stat: Stdv1Stat['errors.errorBoundary.caught'],
    tags: ['boundaryType:appLocaleErrorBoundary'],
  });

  return <ErrorUI statusCode={500} />;
}
